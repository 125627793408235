
































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class HookEditorHome extends Vue {
  @Prop({ type: Boolean }) isDrawerOpen ?: boolean
}
