














































































































































































































































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import Loading from "@/components/Loading.vue"
import { Hook, HookFunctionType } from "@/models"
import { HookFragment, HookUpdateFragment } from "../fragments"
import Fields from "@/components/form/Fields.vue"
import PreviewParams from "@/components/tools/PreviewParams.vue"
import _isEqual from "lodash/isEqual"
import _cloneDeep from "lodash/cloneDeep"
import gql from "graphql-tag"
import cleanData from "@/utils/gql/cleanData"
import { alert, confirmDelete, prompt } from "@/components/dialogs"
import ComponentSelect from "@/components/fields/componentSelect/Field.vue"
import CollectionFieldSelect from "@/components/fields/collectionFieldSelect/Field.vue"
import SingleSelect from "@/components/fields/select/Field.vue"
import ContentField from '@/components/fields/content/Field.vue'
import ComponentEditorDialog from '@/components/ComponentEditorDialog.vue'
import * as monaco from 'monaco-editor'
import MonacoEditor, { editorEnv } from '@/plugins/monaco'
import OptionFields from '@/components/form/OptionFields.vue'
import { ApolloQueryResult } from "node_modules/apollo-client"

@Component({
  components: {
    Loading,
    Fields,
    PreviewParams,
    ComponentSelect,
    CollectionFieldSelect,
    SingleSelect,
    MonacoEditor,
    ComponentEditorDialog,
    ContentField,
    OptionFields
  },
  apollo: {
    savedHook: {
      query: gql`
        query getHook($hookId: ID) {
          savedHook: hook(hookId: $hookId) {
            ...Hook
          }
        }
        ${HookFragment}
      `,
      variables() {
        return {
          hookId: this.componentId,
        }
      },
      result (r : ApolloQueryResult<{ savedHook : Hook }>) {
        this.savedHook = {
          ...r.data.savedHook,
          options: r.data.savedHook.options || {}
        }
      }
    },
  },
})
export default class HookEditorEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) componentId!: string
  @Prop({ type: Array }) functionTypes !: HookFunctionType[] | null
  activeTab = null

  saving = false
  previewParams: any[] = []
  previewError = ""
  codeEditorOptions = {
    automaticLayout: true
  }

  savedHook: Readonly<Hook> | null = null
  hook: Partial<Hook> = {}

  inlineEditComponentType = ''
  inlineEditComponentId = ''
  inlineEditComponentOpen = false

  @Watch('savedHook')
  update (savedHook : Hook) {
    const newHook = _cloneDeep(this.savedHook)!
    if (newHook.functionTypeId === 'script') {
      if (!newHook.options.script) newHook.options.script = {}
      if (newHook.options.script.type !== 'fixed') newHook.options.script.type = 'fixed'
      if (!newHook.options.script.fixed) newHook.options.script.fixed = {}
      if (newHook.options.script.fixed.value == null) newHook.options.script.fixed.value = ''
    }
    if (newHook.functionTypeId === 'generatePdfFromHtmlV2') {
      if (!newHook.options.template) newHook.options.template = {}
      if (newHook.options.template.type !== 'fixed') newHook.options.template.type = 'fixed'
      if (!newHook.options.template.fixed) newHook.options.template.fixed = {}
      if (newHook.options.template.fixed.value == null) newHook.options.template.fixed.value = ''
    }
    this.$set(this, 'hook', newHook)    
  }

  @Watch('hook.name')
  updateName (newName : string) {
    this.$emit('name', newName)
  }

  get hiddenOptions () {
    if (this.hook.functionTypeId === 'script') return ['script']
    if (this.hook.functionTypeId === 'generatePdfFromHtmlV2') return ['template']
    return []
  }

  get dirty () {
    return !_isEqual(this.hook, this.savedHook)
  }

  get functionType () {
    if (!this.functionTypes) return null
    return this.functionTypes.find(f => this.hook.functionTypeId === f._id)
  }

  get schema () {
    if (!this.functionType) return {}
    return this.functionType.optionsParams
  }

  async save () {
    if (!this.hook || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($hookId : ID, $hook: UpdateHookInput) {
          updateHook (hookId: $hookId, hook: $hook) {
            ...Hook
          }
        }
        ${HookFragment}
        `,
        // Parameters
        variables: {
          hookId: this.hook._id,
          hook: cleanData(this.hook, HookUpdateFragment)
        }
      })

      this.savedHook = result.data.updateHook
      this.$emit('updated')
      this.$emit('save', result.data.updateHook)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  async deleteItem () {
    if (!await confirmDelete('¿Seguro que quieres eliminar por completo este hook del ambiente?')) return
    if (!this.hook || this.saving) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`mutation ($hookId : ID) {
          removeHook (hookId: $hookId)
        }`,
        // Parameters
        variables: {
          hookId: this.hook._id
        }
      })
      this.$emit('updated')
      this.$emit('delete', result.data.removeHook)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    }
  }

  async rename () {
    const newName = await prompt('Introduce un nuevo nombre para el hook', { defaultValue: this.hook.name })
    if (newName !== false) {
      this.hook.name = newName as string
      await this.save()
    }
  }

  async duplicate () {
    const cloneName = await prompt([
      'Se duplicará:',
      ' - Este hook y todas sus opciones',
      'NO se duplicará:',
      ' - ID o nombres del hook',
      ' - Cualquier componente (filtros, colecciones, etc) al que este hook haga referencia.',
      '---',
      'Introduce un nombre para el nuevo hook:'
    ].join('\n'), { defaultValue: this.hook.name + ' (Copia)' })
    if (cloneName !== false) {
      try {
        this.saving = true
        const { data } = await this.$apollo.mutate({
          mutation: gql`mutation duplicateHook ($hookId : ID, $newName : String) {
            result: duplicateHook (hookId: $hookId, newName: $newName) {
              _id
            }
          }`,
          variables: {
            hookId: this.componentId,
            newName: cloneName
          }
        })
        const { _id:newId } = data.result
        this.$emit('updated')
        this.$router.push({
          name: 'adminHookEdit',
          params: {
            environmentId: this.environmentId,
            componentId: newId
          }
        })
      } catch (e) {
        console.error(e)
        this.$emit("error", e)
      } finally {
        this.saving = false
      }
    }
  }

  editorDidMount(editor : monaco.editor.IStandaloneCodeEditor) {
    editorEnv.environmentId = this.environmentId
    // Options
    const model = editor.getModel()
    model?.updateOptions({
      tabSize: 2,
      insertSpaces: true
    })
    
    // Actions
    editor.addAction({
      id: 'save',
      label: 'Guardar Cambios',
      keybindings: [
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S
      ],
      run: () => this.save()
    })
    // Events
    editorEnv.onEditComponent = (componentType, componentId) => {
      this.inlineEditComponentOpen = true
      this.inlineEditComponentType = componentType
      this.inlineEditComponentId = componentId
    }
  }
}
