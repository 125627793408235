









































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import gql from 'graphql-tag'
import FuzzySearch from 'fuzzy-search'
import { Hook, HookFunctionType, PaginatedQueryResult } from '@/models'

@Component({
  apollo: {
    hooks: {
      query () {
        return gql`
          query getHooks ($environmentId: ID) {
            hooks (environmentId: $environmentId) {
              items {
                _id
                name
                functionTypeId
              }
            }
          }
        `
      },
      variables () {
        return {
          environmentId: this.environmentId
        }
      },
      fetchPolicy: 'network-only'
    }
  }
})
export default class HookEditorSidebar extends Vue {
  @Prop({ type: String, required: true }) environmentId !: string
  @Prop({ type: Number }) lastUpdated ?: number
  @Prop({ type: Array }) functionTypes !: HookFunctionType[] | null

  hooks : Partial<PaginatedQueryResult<Hook>> = {}
  searchQuery = ''

  get hookResults () {
    if (!this.hooks.items) return []
    const displayResult = this.hooks.items.map((hook) => ({
      ...hook,
      name: hook.name,
      title: hook.name,
      subtitle: this.functionLabel(hook.functionTypeId),
      icon: ({
        script: 'code',
        generatePdfFromHtmlV2: 'description'
      } as Record<string, string>)[hook.functionTypeId] || 'memory'
    }))
    const searcher = new FuzzySearch(displayResult || [], ['name', 'title', 'subtitle', '_id'])
    return searcher.search(this.searchQuery)
  }

  functionLabel (functionTypeId : string) {
    if (!functionTypeId) return 'N/A'
    if (!this.functionTypes) return functionTypeId
    const ftype = this.functionTypes.find(f => f._id === functionTypeId)
    return ftype && ftype.name || functionTypeId    
  }

  @Watch('lastUpdated')
  refreshHookList () {
    this.$apollo.queries.hooks.refresh()
  }
}
